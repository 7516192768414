import React from 'react'
import './Galery.css'

// Imagenes
import img1 from '../images/galeria1.jpg'
import img5 from '../images/galeria5.jpg'
import img9 from '../images/galeria9.jpg'
import img11 from '../images/galeria11.jpg'
import img16 from '../images/galeria16.jpg'
import img17 from '../images/galeria17.jpg'
import img18 from '../images/galeria18.jpg'
import img20 from '../images/galeria20.jpg'

export const Galery = () => {
    return (
        <div className="galery">
            <div className="galery__container">
                <img src={img16} alt="galeria" />
                <img src={img5} alt="galeria" />
                <img src={img9} alt="galeria" />
                <img src={img11} alt="galeria" />
                <img src={img17} alt="galeria" />
                <img src={img1} alt="galeria" />
                <img src={img18} alt="galeria" />
                <img src={img20} alt="galeria" />

            </div>
        </div>
    )
}
