import React from 'react'
import { Galery } from '../components/Galery'
import Layout from '../components/layout'
import SEO from '../components/seo'

const GaleriasPage = () => {
    return (
        <Layout>
            <SEO title="Galería" />
            <Galery />
        </Layout>
    )
}
export default GaleriasPage
